import { Alert, Box, Button, Link, OutlinedInput, Typography } from '@mui/material';
import { COLORS } from '../constants';
import { ArrowLeft } from '../assets/icons/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '../contexts/SessionProvider';
import { useProfileSettings } from '../api/queries/auth/useProfileSettings';
import { QUERY_KEYS } from '../api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { usePasswordReset } from '../api/queries/auth/usePasswordReset';
import { useGetConfig } from '../api/queries/auth/useGetConfig';
import { getLastDashboardFiltersUrl } from '../utils';

type OnSubmitProps = {
  email?: string;
  firstName?: string;
  lastName?: string;
};

export const Settings = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { firstName, lastName, email } = useCurrentUserContext();
  const profileSettings = useProfileSettings();
  const { data: config } = useGetConfig();
  const { link } = usePasswordReset({ keycloakUrl: config?.keycloakUrl });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors, isLoading, isSubmitting, isSubmitSuccessful },
  } = useForm();

  useEffect(() => {
    setValue('firstName', firstName);
    setValue('lastName', lastName);
    setValue('email', email);
  }, [firstName, lastName, email, setValue]);

  const isError = !!errors.firstName || !!errors.lastName || !!errors.email;

  const onSubmit = async ({ firstName, lastName }: OnSubmitProps) => {
    try {
      if (!firstName || !lastName) return;
      await profileSettings.mutateAsync({
        firstName,
        lastName,
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SESSION] });
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const errCode = err?.response?.data?.code;
        setError('customError', {
          type: 'custom',
          message: errCode ? t(`error.${errCode}`) : err.message,
        });
      }
    }
  };

  const isApiError = errors.customError;

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ ml: 6, mr: 6, mt: 1, mb: 3 }}>
      <Box sx={{ display: 'flex', py: 1.5, alignItems: 'center' }}>
        <Box
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          onClick={() => navigate(getLastDashboardFiltersUrl())}
        >
          <ArrowLeft />
        </Box>
        <Typography
          sx={{
            fontSize: '1.25rem',
            ml: 1,
            color: COLORS.BLUE_THIRD,
            fontWeight: 500,
            cursor: 'pointer',
          }}
          onClick={() => navigate(getLastDashboardFiltersUrl())}
        >
          {t('common.settings')}
        </Typography>
      </Box>
      <Box>
        {['firstName', 'lastName', 'email'].map((name) => (
          <Box key={name} sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <Box sx={{ minWidth: '176px', mr: 1 }}>
              <Typography variant="label">{t(`common.${name}`)}</Typography>
            </Box>
            <OutlinedInput
              id={name}
              sx={{
                width: '296px',
              }}
              size="small"
              disabled={name === 'email'}
              error={!!errors[name]}
              {...register(name, { required: true, onChange: () => clearErrors() })}
            />
          </Box>
        ))}
        <Box>
          <Link href={link} variant="body2" sx={{ color: COLORS.SECONDARY }}>
            {t('settings.changePassword')}
          </Link>
        </Box>
        {isApiError && (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Alert severity="error" sx={{ width: '100%', maxWidth: '600px' }}>
              {errors?.customError?.message as string}
            </Alert>
          </Box>
        )}
        {isSubmitSuccessful && (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Alert severity="success" sx={{ width: '100%', maxWidth: '600px' }}>
              {t('settings.successful')}
            </Alert>
          </Box>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="medium"
          color="secondary"
          disabled={isLoading || isSubmitting || isError}
          sx={{ maxWidth: '530px', mt: 5 }}
        >
          {t('settings.saveChanges')}
        </Button>
      </Box>
    </Box>
  );
};
