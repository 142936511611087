import Box from '@mui/material/Box';
import { SearchInput } from '../SearchInput';
import { CustomDatePicker } from '../DatePicker';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext, MouseEvent } from 'react';
import { PatientListContext, REVIEW_STATUS } from '../../contexts/PatientListProvider';
import dayjs from 'dayjs';

export const FilterBar = () => {
  const { t } = useTranslation();
  const {
    query: { from, to, reviewStatus },
    setQuery,
  } = useContext(PatientListContext);

  const handleStatusClick = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.target as HTMLInputElement;
    if (value === reviewStatus) {
      setQuery?.({ reviewStatus: null });
    } else {
      setQuery?.({ reviewStatus: value });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px 24px', flexWrap: 'wrap' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <SearchInput />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CustomDatePicker
          placeholder={t('datepicker.startDate')}
          label={t('datepicker.selectStartDate')}
          value={from ? dayjs(from).toDate() : null}
          maxDate={to ? dayjs(to).toDate() : null}
          onChange={(from) => setQuery({ from, page: 0 })}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CustomDatePicker
          placeholder={t('datepicker.endDate')}
          label={t('datepicker.selectEndDate')}
          value={to ? dayjs(to).toDate() : null}
          minDate={from ? dayjs(from).toDate() : null}
          onChange={(to) => setQuery({ to, page: 0 })}
        />
      </Box>
      <RadioGroup
        aria-labelledby="status"
        name="status"
        sx={{
          pb: '4px',
          minWidth: '358px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
          justifyContent: 'start',
        }}
        onChange={(e) => setQuery?.({ reviewStatus: e.target.value, page: 0 })}
      >
        <FormControlLabel
          value={REVIEW_STATUS.REVIEWED}
          checked={reviewStatus === REVIEW_STATUS.REVIEWED}
          control={<Radio onClick={handleStatusClick} />}
          label={t('status.REVIEWED')}
        />
        <FormControlLabel
          value={REVIEW_STATUS.AWAITING_REVIEW}
          checked={reviewStatus === REVIEW_STATUS.AWAITING_REVIEW}
          control={<Radio onClick={handleStatusClick} />}
          label={t('status.AWAITING_REVIEW')}
        />
      </RadioGroup>
    </Box>
  );
};
