import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetPatientFile } from '../api/queries/patient/useGetFile';
import ReactPlayer from 'react-player/lazy';

type VideoPlayerProviderProps = {
  children: ReactNode;
};

export const VideoPlayerContext = createContext<any>({});

export const useVideoPlayerContext = () => {
  const videoPlayer = useContext(VideoPlayerContext);

  if (!videoPlayer) {
    throw new Error('VideoPlayerContext: No value provided');
  }

  return videoPlayer;
};

const DEFAULT_STATE = {
  playing: false,
  muted: false,
  volume: 1,
  played: 0,
  seeking: false,
  isFullscreen: false,
  buffer: false,
};

export type VideoStateProps = {
  playing: boolean;
  muted: boolean;
  volume: number;
  played: number;
  seeking: boolean;
  isFullscreen: boolean;
  buffer: boolean;
};

export const VideoPlayerProvider = ({ children }: VideoPlayerProviderProps) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false);
  const [videoState, setVideoState] = useState<VideoStateProps>(DEFAULT_STATE);
  const { patientId, selectedFileId } = useParams();
  const { data, isLoading, isFetching } = useGetPatientFile({
    patientId: patientId || '',
    fileId: selectedFileId || '',
  });

  const resetVideo = () => {
    setIsPlayerReady(false);
    setFileUrl(null);
    setVideoState(DEFAULT_STATE);
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      buffer: true,
    }));
  };

  useEffect(() => {
    if (!data || !selectedFileId) return;
    resetVideo();
  }, [selectedFileId]);

  return (
    <VideoPlayerContext.Provider
      value={{
        selectedFileId,
        ...data,
        isPlayerReady,
        setIsPlayerReady,
        videoState,
        setVideoState,
        videoPlayerRef,
        fileUrl,
      }}
    >
      {isLoading || isFetching ? (
        <Box
          sx={{
            width: '478px',
            height: '269px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </VideoPlayerContext.Provider>
  );
};
