import { useMutation, useQueryClient } from '@tanstack/react-query';
import { REVIEW_RESULT_STATUS, REVIEW_SEIZURE_TYPE } from './constants';
import { baseClient } from '../client/baseClient';
import { QUERY_KEYS } from '../index';

type REVIEW_DTO = {
  fileId: string;
  reviewResult: REVIEW_RESULT_STATUS;
  seizureType: REVIEW_SEIZURE_TYPE | null;
  description?: string;
};

export const useReview = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      patientId,
      reviewData,
    }: {
      patientId: string;
      reviewData: REVIEW_DTO;
    }): Promise<void> => {
      await submitReviewData(patientId, reviewData);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENTS] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENT_FILES] });
    },
  });
};

const submitReviewData = async (patientId: string, data: REVIEW_DTO): Promise<void> => {
  return baseClient({
    url: `/patients/${patientId}/review`,
    method: 'POST',
    data,
  });
};
