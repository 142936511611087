import { SyntheticEvent, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { COLORS } from '../../constants';
import { PlayerIcon } from '../../assets/icons/PlayerIcon';
import { findDOMNode } from 'react-dom';
import { FullscreenControls } from './FullscreenControls';
import { Controls } from './Controls';
import { FullscreenButton } from './FullscreenButton';
import screenfull from 'screenfull';
import { CircularProgress, styled } from '@mui/material';
import { useVideoPlayerContext, VideoStateProps } from '../../contexts/VideoPlayerContext';
import { Video } from './Video';

export const VideoPlayer = () => {
  const { videoPlayerRef, videoState, setVideoState, fileUrl, thumbnailUrl } =
    useVideoPlayerContext();
  const wrapperRef = useRef(null);

  const { playing, isFullscreen, buffer } = videoState;

  const seekHandler = (e: SyntheticEvent<Element, Event> | Event) => {
    const target = e.target as HTMLInputElement;
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      played: parseFloat(target.value) / 100,
    }));
  };

  const playPauseHandler = () => {
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      playing: !videoState.playing,
    }));
  };

  const seekMouseUpHandler = (e: SyntheticEvent | Event, value: number | number[]) => {
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      seeking: false,
    }));
    videoPlayerRef?.current?.seekTo(((!Array.isArray(value) && value) || 0) / 100);
  };

  const onSeekMouseDownHandler = () => {
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      seeking: true,
    }));
  };

  const handleToggleFullscreen = () => {
    if (screenfull.isFullscreen) {
      screenfull.exit();
    } else {
      // @ts-ignore
      screenfull.request(findDOMNode(wrapperRef.current));
    }
  };

  const handleFullscreenChange = () => {
    setVideoState((prevVideoState: VideoStateProps) => ({
      ...prevVideoState,
      isFullscreen: screenfull.isFullscreen,
    }));
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <Box
      key={`box-${fileUrl}`}
      sx={{
        position: 'relative',
        width: '100%',
        height: '269px',
      }}
      ref={wrapperRef}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          borderRadius: '7px',
        }}
      >
        <PosterBoxStyled
          sx={{
            backgroundImage: `url(${thumbnailUrl})`,
          }}
        />
        {buffer && (
          <LoaderBoxStyled>
            <CircularProgress />
          </LoaderBoxStyled>
        )}
        {!playing && !buffer && (
          <PlayIconBoxStyled onClick={playPauseHandler}>
            <PlayerIcon />
          </PlayIconBoxStyled>
        )}
        {!isFullscreen && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '18px',
              right: '18px',
              zIndex: 100,
              'svg path': {
                fill: COLORS.WHITE,
              },
            }}
          >
            <FullscreenButton
              isActive={isFullscreen}
              handleToggleFullscreen={handleToggleFullscreen}
            />
          </Box>
        )}
        <Video />
        {isFullscreen && (
          <FullscreenControls
            playPauseHandler={playPauseHandler}
            seekHandler={seekHandler}
            seekMouseUpHandler={seekMouseUpHandler}
            onSeekMouseDownHandler={onSeekMouseDownHandler}
            isFullscreenActive={isFullscreen}
            handleToggleFullscreen={handleToggleFullscreen}
          />
        )}
      </Box>
      <Controls
        handlePlayPause={playPauseHandler}
        seekHandler={seekHandler}
        seekMouseUpHandler={seekMouseUpHandler}
        onSeekMouseDownHandler={onSeekMouseDownHandler}
      />
    </Box>
  );
};

const PosterBoxStyled = styled(Box)(() => ({
  borderRadius: '7px',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  filter: 'blur(7px)',
  zIndex: -1,
  overflow: 'hidden',
  backgroundSize: 'cover',
  '&:before': {
    borderRadius: '7px',
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: COLORS.BLACK_PRIMARY,
    opacity: 0.01,
  },
}));

const LoaderBoxStyled = styled(Box)(() => ({
  position: 'absolute',
  height: '68px',
  width: '68px',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2000,
}));

const PlayIconBoxStyled = styled(Box)(() => ({
  cursor: 'pointer',
  position: 'absolute',
  height: '68px',
  width: '68px',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '4px',
  zIndex: 100,
  svg: {
    height: '68px',
    width: '68px',
  },
}));
