import { MouseEvent } from 'react';
import { Box } from '@mui/material';
import { ArrowUpIcon } from '../../assets/icons/ArrowUpIcon';
import { ArrowDownIcon } from '../../assets/icons/ArrowDownIcon';
import { PATIENT_LIST_ORDER, PATIENT_LIST_SORT } from '../../api/queries/patient/usePatients';
import { COLORS } from '../../constants';

type ArrowSortTableProps = {
  isActive: boolean;
  order?: PATIENT_LIST_ORDER;
  name: PATIENT_LIST_SORT;
  handleChange: (sort: PATIENT_LIST_SORT, order: PATIENT_LIST_ORDER) => void;
};

export const ArrowSortTable = ({ isActive, order, name, handleChange }: ArrowSortTableProps) => {
  const handleClick = (
    e: MouseEvent<HTMLElement>,
    name: PATIENT_LIST_SORT,
    order: PATIENT_LIST_ORDER
  ) => {
    e.stopPropagation();
    handleChange(name, order);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: ' column', pl: 0.5, zIndex: 50 }}>
      <Box
        onClick={(e) => handleClick(e, name, PATIENT_LIST_ORDER.ASC)}
        sx={{
          zIndex: 50,
          'svg path': {
            fill:
              isActive && order === PATIENT_LIST_ORDER.ASC
                ? COLORS.BLACK_PRIMARY
                : COLORS.BLUE_PRIMARY,
          },
        }}
      >
        <ArrowUpIcon />
      </Box>
      <Box
        onClick={(e) => handleClick(e, name, PATIENT_LIST_ORDER.DESC)}
        sx={{
          zIndex: 50,
          'svg path': {
            fill:
              isActive && order === PATIENT_LIST_ORDER.DESC
                ? COLORS.BLACK_PRIMARY
                : COLORS.BLUE_PRIMARY,
          },
        }}
      >
        <ArrowDownIcon />
      </Box>
    </Box>
  );
};
