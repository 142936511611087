import { Box, Typography } from '@mui/material';
import { COLORS } from '../../constants';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetPatientFile } from '../../api/queries/patient/useGetFile';
import { getDateInLocaleFormat } from '../../utils';

export const PatientDetails = () => {
  const { t } = useTranslation();
  const { patientId, selectedFileId } = useParams();
  const { data: patientFile } = useGetPatientFile({
    patientId: patientId || '',
    fileId: selectedFileId || '',
  });

  const createdAt = patientFile?.fileCreatedAt
    ? getDateInLocaleFormat(patientFile.fileCreatedAt)
    : '-';
  const uploadedAt = patientFile?.fileUploadedAt
    ? getDateInLocaleFormat(patientFile.fileUploadedAt)
    : '-';

  return (
    <Box
      sx={{
        backgroundColor: COLORS.BLUE_SECOND,
        color: COLORS.SECONDARY,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        borderRadius: '12px',
        p: 3,
        height: '100%',
      }}
    >
      <Box>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {t('patient.parentName')}
        </Typography>
        <Typography variant="body1">
          {!patientFile?.parentFirstName || !patientFile?.parentLastName
            ? '-'
            : `${patientFile?.parentFirstName} ${patientFile?.parentLastName}`}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {t('patient.parentMail')}
        </Typography>
        <Typography variant="body1">{patientFile?.parentEmail}</Typography>
      </Box>
      <Box>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {t('patient.videoTitle')}
        </Typography>
        <Typography variant="body1">{patientFile?.fileName}</Typography>
      </Box>
      <Box>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {t('patient.videoRecordedAt')}
        </Typography>
        <Typography variant="body1">{createdAt}</Typography>
      </Box>
      <Box>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {t('patient.receivedForInspectionAt')}
        </Typography>
        <Typography>{uploadedAt}</Typography>
      </Box>
    </Box>
  );
};
