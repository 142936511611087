import { Button, Menu, MenuItem, Radio, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { DropdownIcon } from '../../assets/icons/DropdownIcon';
import { MouseEvent } from 'react';
import { COLORS, LOCALES, MENU_ID } from '../../constants';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '../../contexts/SessionProvider';
import { useState } from 'react';
import { logOut } from '../../api/queries/auth/useLogout';
import { GearIcon } from '../../assets/icons/GearIcon';
import { SignOutIcon } from '../../assets/icons/SignOutIcon';
import { useNavigate } from 'react-router-dom';

export const ProfileButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language);

  const { firstName } = useCurrentUserContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuLanguageClick = (language: string) => {
    setCurrentLanguage(language);
    changeLanguage(language);
    localStorage.setItem('selectedLanguage', language);
    handleMenuClose();
    window.location.reload();
  };

  const isActiveLanguage = (key: string) => key === currentLanguage;

  const handleLinkClick = (link: string) => {
    handleMenuClose();
    navigate(link);
  };

  const renderMenu = (
    <MenuStyled
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 56,
        horizontal: 'left',
      }}
      id={MENU_ID}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => handleLinkClick('/settings')}
        sx={{
          '&:hover svg path': {
            fill: COLORS.WHITE,
          },
        }}
      >
        <IconWrapper>
          <GearIcon />
        </IconWrapper>
        {t('common.settings')}
      </MenuItem>
      {Object.entries(LOCALES).map(([key, { title, Icon }]) => (
        <MenuItem
          key={`language-${title}`}
          sx={{ minWidth: '172px', minHeight: '60px' }}
          onClick={() => handleMenuLanguageClick(key)}
        >
          <IconWrapper>
            <Icon />
          </IconWrapper>
          {title}
          <Radio sx={{ marginLeft: 'auto' }} checked={isActiveLanguage(key)} />
        </MenuItem>
      ))}
      <MenuItem
        onClick={logOut}
        sx={{
          '&:hover svg path': {
            fill: COLORS.WHITE,
          },
        }}
      >
        <IconWrapper>
          <SignOutIcon />
        </IconWrapper>
        {t('common.logout')}
      </MenuItem>
    </MenuStyled>
  );

  return (
    <>
      <Button
        type="submit"
        fullWidth
        variant="outlined"
        size="small"
        sx={{
          p: '4px 6px',
          fontSize: '1.125rem',
          m: 0,
        }}
        aria-label="menu"
        aria-controls={MENU_ID}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
      >
        <LetterBox>{firstName?.[0]}</LetterBox>
        {t('navbar.profile')}
        <Box sx={{ pl: 0.5, m: 0, display: 'flex' }}>
          <DropdownIcon />
        </Box>
      </Button>
      {renderMenu}
    </>
  );
};

const MenuStyled = styled(Menu)(() => ({
  '.MuiPaper-root': {
    borderRadius: '8px',
  },
  '.MuiList-root': {
    background: COLORS.GREY_THIRD,
    padding: 0,
    margin: 0,
    borderRadius: '8px',
  },
  '.MuiButtonBase-root': {
    height: '50px',
  },
  '.MuiButtonBase-root:hover svg': {
    fill: COLORS.WHITE,
  },
}));

const IconWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  marginRight: '6px',
}));

const LetterBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: COLORS.BLACK_SECOND,
  fontSize: '1.5rem',
  marginRight: '8px',
  fontWeight: '600',
  background: 'rgba(255, 255, 255, 0.90)',
  width: '38px',
  height: '38px',
  borderRadius: '100px',
}));
