import {
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useContext } from 'react';
import Box from '@mui/material/Box';
import { COLORS } from '../../constants';
import { Status } from '../Status';
import { ArrowSortTable } from '../ArrowSortTable';
import { ROW_PER_PAGE_OPTIONS } from './constants';
import { useTranslation } from 'react-i18next';
import { DEFAULT_ROWS_PER_PAGE, PatientListContext } from '../../contexts/PatientListProvider';
import { PATIENT_LIST_ORDER, PATIENT_LIST_SORT } from '../../api/queries/patient/usePatients';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { selectedLanguage } from 'index';

const PatientsList = () => {
  const {
    content,
    totalCount,
    query: { rowsPerPage, page, order, sort },
    setQuery,
  } = useContext(PatientListContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleChangePage = (event: unknown, newPage: number) => {
    setQuery({ page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery({ rowsPerPage: +event.target.value, page: 0 });
  };

  const toggleOrder = (sort: PATIENT_LIST_SORT, isActive: boolean) => {
    setQuery({
      sort,
      order: isActive
        ? PATIENT_LIST_ORDER.ASC === order
          ? PATIENT_LIST_ORDER.DESC
          : PATIENT_LIST_ORDER.ASC
        : PATIENT_LIST_ORDER.DESC,
    });
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell
                onClick={() =>
                  toggleOrder(
                    PATIENT_LIST_SORT.REVIEW_STATUS,
                    PATIENT_LIST_SORT.REVIEW_STATUS === sort
                  )
                }
              >
                <StyledTableCellWrapper>
                  {t('dashboard.status')}
                  <ArrowSortTable
                    name={PATIENT_LIST_SORT.REVIEW_STATUS}
                    isActive={PATIENT_LIST_SORT.REVIEW_STATUS === sort}
                    order={order}
                    handleChange={(sort, order) => setQuery({ sort, order })}
                  />
                </StyledTableCellWrapper>
              </StyledTableCell>
              <StyledTableCell
                onClick={() =>
                  toggleOrder(PATIENT_LIST_SORT.RESULT, PATIENT_LIST_SORT.RESULT === sort)
                }
              >
                <StyledTableCellWrapper>
                  {t('dashboard.result')}
                  <ArrowSortTable
                    name={PATIENT_LIST_SORT.RESULT}
                    isActive={PATIENT_LIST_SORT.RESULT === sort}
                    order={order}
                    handleChange={(sort, order) => setQuery({ sort, order })}
                  />
                </StyledTableCellWrapper>
              </StyledTableCell>
              <StyledTableCell
                onClick={() => toggleOrder(PATIENT_LIST_SORT.NAME, PATIENT_LIST_SORT.NAME === sort)}
              >
                <StyledTableCellWrapper>
                  {t('dashboard.patient')}
                  <ArrowSortTable
                    name={PATIENT_LIST_SORT.NAME}
                    isActive={PATIENT_LIST_SORT.NAME === sort}
                    order={order}
                    handleChange={(sort, order) => setQuery({ sort, order })}
                  />
                </StyledTableCellWrapper>
              </StyledTableCell>
              <StyledTableCell
                onClick={() =>
                  toggleOrder(
                    PATIENT_LIST_SORT.FILES_TO_REVIEW,
                    PATIENT_LIST_SORT.FILES_TO_REVIEW === sort
                  )
                }
              >
                <StyledTableCellWrapper>
                  {t('dashboard.awaitingReview')}/
                  <br />
                  {t('dashboard.reviewed')}
                  <ArrowSortTable
                    name={PATIENT_LIST_SORT.FILES_TO_REVIEW}
                    isActive={PATIENT_LIST_SORT.FILES_TO_REVIEW === sort}
                    order={order}
                    handleChange={(sort, order) => setQuery({ sort, order })}
                  />
                </StyledTableCellWrapper>
              </StyledTableCell>
              <StyledTableCell
                onClick={() => toggleOrder(PATIENT_LIST_SORT.DATE, PATIENT_LIST_SORT.DATE === sort)}
              >
                <StyledTableCellWrapper>
                  {t('dashboard.date')}
                  <ArrowSortTable
                    name={PATIENT_LIST_SORT.DATE}
                    isActive={PATIENT_LIST_SORT.DATE === sort}
                    order={order}
                    handleChange={(sort, order) => setQuery({ sort, order })}
                  />
                </StyledTableCellWrapper>
              </StyledTableCell>
              <StyledTableCell>
                <StyledTableCellWrapper>{t('dashboard.video')}</StyledTableCellWrapper>
              </StyledTableCell>
              <StyledTableCell>
                <StyledTableCellWrapper>{t('dashboard.action')}</StyledTableCellWrapper>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {content?.length === 0 && (
            <StyledTableRow>
              <TableCell sx={{ textAlign: 'center', p: 4 }} colSpan={7}>
                {t('common.noRecordsFound')}
              </TableCell>
            </StyledTableRow>
          )}
          <TableBody>
            {content?.map(
              ({
                childId,
                fileReviewStatus,
                reviewResult,
                childFirstName,
                childLastName,
                parentFirstName,
                parentLastName,
                filesToReviewCount,
                totalFilesCount,
                recentlyUploadedFileDate,
                thumbnailUrl,
                invitationCode,
              }) => (
                <StyledTableRow
                  key={childId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Status variant={fileReviewStatus} />
                  </TableCell>
                  <TableCell>{reviewResult ? t(`reviewResult.${reviewResult}`) : null}</TableCell>
                  <TableCell>
                    <Box alignItems="flex-start" display="flex" flexDirection="column">
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        {childFirstName} {childLastName}
                      </Typography>
                      <Typography sx={{ fontSize: '0.875rem' }}>
                        {parentFirstName && parentLastName
                          ? `${parentFirstName} ${parentLastName}`
                          : invitationCode}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {filesToReviewCount}/{totalFilesCount}
                  </TableCell>
                  <TableCell>
                    {dayjs(recentlyUploadedFileDate).locale(selectedLanguage).format('L')}
                  </TableCell>
                  <TableCell>
                    {thumbnailUrl ? (
                      <img
                        height="70px"
                        alt={`${childFirstName} ${childLastName}`}
                        src={thumbnailUrl}
                      />
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="medium"
                      color="secondary"
                      sx={{ maxWidth: 148 }}
                      onClick={() => navigate(`/dashboard/patient/${childId}`)}
                    >
                      {t('common.details')}
                    </Button>
                  </TableCell>
                </StyledTableRow>
              )
            )}
          </TableBody>
          <TableFooter>
            <tr>
              <TablePaginationStyled
                rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                count={totalCount || 0}
                rowsPerPage={rowsPerPage || DEFAULT_ROWS_PER_PAGE}
                page={page || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('common.rowsPerPage')}
                colSpan={7}
              />
            </tr>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

const TablePaginationStyled = styled(TablePagination)(() => ({
  border: 'none',
  width: '100%',
  '.MuiToolbar-root': {
    width: '100%',
  },
  '.MuiTablePagination-spacer': {
    flex: 'none',
  },
  '.MuiTablePagination-actions': {
    marginLeft: 'auto!important',
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: COLORS.TABLE_ROW,
  },
  tr: {
    height: '80px',
  },
  'td, th': {
    border: 0,
    height: 'auto!important',
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: '1.125rem',
  cursor: 'pointer',
}));

const StyledTableCellWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export default PatientsList;
