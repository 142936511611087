import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  TextareaAutosize,
  TextareaAutosizeProps,
  Typography,
} from '@mui/material';
import { COLORS } from '../../constants';
import { useTranslation } from 'react-i18next';
import { REVIEW_RESULT_STATUS, REVIEW_SEIZURE_TYPE } from '../../api/queries/patient/constants';
import { PatientReviewArrows } from '../PatientReviewArrows';
import { useForm } from 'react-hook-form';
import { useReview } from '../../api/queries/patient/useReview';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { usePatientFiles } from '../../api/queries/patient/usePatientFiles';
import { getAwaitingReviewFiles } from '../../api/queries/patient/utils';
import { MODAL_TYPE } from '../../contexts/ModalContext';
import { useModal } from '../../hooks/useModal';
import { PatientSidebarContext } from '../../contexts/PatientSidebarProvider';

export const PatientReview = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { content: patientList } = useContext(PatientSidebarContext);
  const { patientId, selectedFileId } = useParams();
  const { data } = usePatientFiles({ patientId: patientId || '' });
  const videoFiles = data?.files;

  const review = useReview();

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors, isLoading, isSubmitting, isDirty },
  } = useForm();

  const onSubmit = async ({ reviewResult, seizureType, description }: any) => {
    try {
      clearErrors();
      if (!patientId || !selectedFileId) return;

      await review.mutateAsync({
        patientId,
        reviewData: {
          reviewResult,
          seizureType: reviewResult === REVIEW_RESULT_STATUS.DETECTED ? seizureType : null,
          description,
          fileId: selectedFileId,
        },
      });

      reset(undefined, { keepValues: false, keepDirty: false, keepDefaultValues: false });
      const awaitingFiles = getAwaitingReviewFiles(data?.files);

      if (awaitingFiles.length === 1) {
        const currenPatientIndex = patientList.findIndex(({ childId }) => childId === patientId);
        const nextPatient =
          patientList[typeof currenPatientIndex === 'number' ? currenPatientIndex + 1 : 0];
        openModal(MODAL_TYPE.ALL_VIDEOS_ANALYZED, {
          callback: () => {
            navigate(
              `/dashboard/patient/${nextPatient ? nextPatient.childId : patientList[0]?.childId}`,
              { state: 'force' }
            );
          },
        });
      } else {
        const currenVideoIndex = awaitingFiles?.findIndex(
          ({ fileId }) => fileId === selectedFileId
        );
        const nextVideo =
          awaitingFiles?.[typeof currenVideoIndex === 'number' ? currenVideoIndex + 1 : 0];
        navigate(
          `/dashboard/patient/${patientId}/file/${
            nextVideo ? nextVideo.fileId : videoFiles?.[0]?.fileId
          }`,
          { state: 'force' }
        );
      }
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const errCode = err?.response?.data?.code;

        if (errCode === 'REVIEW_ALREADY_CREATED') {
          openModal(MODAL_TYPE.REVIEW_ALREADY_CREATED);
        } else {
          setError('customError', {
            type: 'custom',
            message: errCode ? t(`error.${errCode}`) : err.message,
          });
        }
      }
    }
  };

  const isDetected = getValues('reviewResult') === REVIEW_RESULT_STATUS.DETECTED;

  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  let shouldBlock = useCallback(
    ({ currentLocation, nextLocation }: any) => {
      if (
        !confirmedNavigation &&
        isDirty &&
        currentLocation.pathname !== nextLocation.pathname &&
        nextLocation.state !== 'force'
      ) {
        setLastLocation(nextLocation);
        openModal(MODAL_TYPE.CANCEL_REVIEW, {
          callback: () => {
            setConfirmedNavigation(true);
          },
        });
        return true;
      } else {
        return false;
      }
    },
    [isDirty]
  );

  let blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      setConfirmedNavigation(false);
      blocker?.proceed?.();
      // @ts-ignore
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isDirty) {
      blocker.reset();
    }
  }, [blocker, isDirty]);

  return (
    <Box sx={{ mb: 3, maxWidth: '750px' }}>
      <FormControl component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ maxWidth: '610px' }}>
          <Typography variant="body1" sx={{ color: '#212121' }}>
            {t('patient.resultTitle')}
          </Typography>
          <RadioGroup row aria-labelledby="review-result-group">
            {Object.values(REVIEW_RESULT_STATUS).map((reviewResult) => (
              <FormControlLabel
                key={`${selectedFileId}${reviewResult}`}
                {...register('reviewResult', {
                  required: true,
                  onChange: () => clearErrors(),
                })}
                checked={getValues('reviewResult') === reviewResult}
                value={reviewResult}
                control={<Radio color={errors.reviewResult ? 'error' : 'default'} />}
                label={t(`reviewResult.${reviewResult}`)}
              />
            ))}
          </RadioGroup>
          {isDetected && (
            <RadioGroup row aria-labelledby="seizure-type-group">
              {Object.values(REVIEW_SEIZURE_TYPE).map((seizureType) => (
                <FormControlLabel
                  key={`${selectedFileId}${seizureType}`}
                  {...register('seizureType', {
                    required: false,
                    onChange: () => clearErrors(),
                    validate: {
                      required: () => {
                        if (isDetected && !getValues('seizureType')) return 'invalid';
                        return true;
                      },
                    },
                  })}
                  checked={getValues('seizureType') === seizureType}
                  value={seizureType}
                  control={<Radio color={errors.seizureType ? 'error' : 'default'} />}
                  label={t(`reviewSeizure.${seizureType}`)}
                />
              ))}
            </RadioGroup>
          )}
        </Box>
        <Box sx={{ maxWidth: '498px' }}>
          <TextareaAutosizeStyled
            key={`${selectedFileId}`}
            placeholder={t('patient.description.placeholder')}
            minRows="8"
            {...register('description', {
              required: false,
              onChange: () => clearErrors(),
            })}
          />
          {errors?.customError && (
            <Box sx={{ mt: 2, mb: 2 }}>
              <Alert severity="error" sx={{ width: '100%', maxWidth: '600px' }}>
                {errors?.customError?.message as string}
              </Alert>
            </Box>
          )}
          <Box sx={{ mt: '27px', gap: 2, display: 'flex', flexWrap: 'wrap' }}>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              color="secondary"
              sx={{ minWidth: '290px', mr: 3 }}
              disabled={isLoading || isSubmitting}
            >
              {t('patient.submit')}
            </Button>
            <PatientReviewArrows />
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              color="secondary"
              onClick={() => openModal(MODAL_TYPE.SKIP_ALL_VIDEOS)}
              disabled={isLoading || isSubmitting}
            >
              {t('patient.submitAndSkip')}
            </Button>
          </Box>
        </Box>
      </FormControl>
      <Alert
        severity="error"
        sx={{
          mt: 3,
          width: '750px',
        }}
      >
        {t('patient.submitAlert')}
      </Alert>
    </Box>
  );
};

const TextareaAutosizeStyled = styled(TextareaAutosize)<TextareaAutosizeProps>(() => ({
  width: '100%',
  color: COLORS.BLUE_THIRD,
  marginTop: '6px',
  '&': {
    padding: '12px 16px',
    fontFamily: '"Baloo-2", sans-serif',
    borderWidth: '2px',
    borderColor: COLORS.BLACK_SECOND,
    borderRadius: '8px',
    color: COLORS.BLUE_THIRD,
  },
}));
