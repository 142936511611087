import { useMutation } from '@tanstack/react-query';
import { baseClient } from '../client/baseClient';

type useInvitationSendProps = {
  email: string;
  code: string;
};

export const useInvitationCodeSend = () =>
  useMutation({
    mutationFn: async (payload: useInvitationSendProps): Promise<void> => {
      await invitationCodeSend(payload);
    },
  });

const invitationCodeSend = async (data: useInvitationSendProps): Promise<void> => {
  return baseClient({
    url: `/invitation/send`,
    method: 'POST',
    data,
  });
};
