import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { LoginLayout } from './components/layouts/LoginLayout';
import { Login } from './pages/Login';
import { Layout } from './components/layouts/Layout';
import { Settings } from './pages/Settings';
import { Dashboard } from './pages/Dashboard';
import { LayoutSidebar } from './components/layouts/LayoutSidebar';
import { Patient } from './pages/Patient';
import { NotFound } from './pages/NotFound';
import React from 'react';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/" element={<LoginLayout />}>
        <Route index element={<Login />} />
      </Route>
      <Route path="/settings" element={<Layout />}>
        <Route index element={<Settings />} />
      </Route>
      <Route path="/dashboard" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="/dashboard/patient/:patientId" element={<LayoutSidebar />}>
          <Route index />
          <Route path="/dashboard/patient/:patientId/file/:selectedFileId" element={<Patient />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
