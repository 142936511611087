import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';

export const LoginLayout = () => {
  return (
    <Container sx={{ p: '0!important', m: 0 }} maxWidth={false}>
      <Outlet />
    </Container>
  );
};
