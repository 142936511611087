import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../index';
import { baseClient } from '../client/baseClient';

export type SessionRO = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

export const useSession = () =>
  useQuery({
    queryKey: [QUERY_KEYS.SESSION],
    queryFn: verification,
  });

const verification = async (): Promise<SessionRO> =>
  baseClient({
    url: '/users/me',
    method: 'GET',
  });
