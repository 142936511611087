export enum REVIEW_RESULT_STATUS {
  DETECTED = 'DETECTED',
  NOT_DETECTED = 'NOT_DETECTED',
  UNCERTAIN = 'UNCERTAIN',
  SKIPPED = 'SKIPPED',
}

export enum REVIEW_SEIZURE_TYPE {
  INFANTILE_SPASM = 'INFANTILE_SPASM',
  FOCAL_SEIZURE = 'FOCAL_SEIZURE',
  TONIC_CLONIC_SEIZURE = 'TONIC_CLONIC_SEIZURE',
  OTHER_MOVEMENT = 'OTHER_MOVEMENT',
}
