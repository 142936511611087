import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Container, CssBaseline, ThemeProvider } from '@mui/material';
import { DEFAULT_LANGUAGE, mainTheme } from './constants';
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { defaultOptions } from './config/reactQueryConfig';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import enJSON from './locales/en.json';
import deJSON from './locales/de.json';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { de, enGB } from 'date-fns/locale';
import { USER_BROWSER_LANGUAGE } from './utils';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

require('dayjs/locale/de');
require('dayjs/locale/en');

const queryClient = new QueryClient({
  defaultOptions,
});

export const selectedLanguage: string =
  localStorage.getItem('selectedLanguage') || USER_BROWSER_LANGUAGE || DEFAULT_LANGUAGE;

dayjs.extend(localizedFormat);
dayjs.locale(selectedLanguage);

i18next.init({
  interpolation: { escapeValue: false },
  lng: selectedLanguage,
  resources: {
    en: enJSON,
    de: deJSON,
  },
});

const LANGUAGE_CONFIG: { [key: string]: Locale } = {
  de: de,
  en: enGB,
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={mainTheme}>
        <I18nextProvider i18n={i18next}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={LANGUAGE_CONFIG[selectedLanguage]}
          >
            <Container component="main" maxWidth={false} disableGutters>
              <CssBaseline />
              <App />
            </Container>
          </LocalizationProvider>
        </I18nextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
