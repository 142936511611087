import { Box, Typography } from '@mui/material';
import { PatientVideo } from '../components/PatientVideo';
import { PatientReview } from '../components/PatientReview';
import { useParams } from 'react-router-dom';
import { useGetPatientFile } from '../api/queries/patient/useGetFile';
import { PatientReviewResult } from '../components/PatientReviewResult';
import { PatientDetails } from '../components/PatientDetails';

export const Patient = () => {
  const { patientId, selectedFileId } = useParams();
  const {
    data: patientFile,
    isLoading,
    isFetched,
  } = useGetPatientFile({
    patientId: patientId || '',
    fileId: selectedFileId || '',
  });
  const isReviewed = !!patientFile?.reviewResult;

  return (
    <Box sx={{ mt: '50px', mb: 3 }}>
      {!isLoading && isFetched && (
        <>
          <Typography variant="h6" sx={{ fontWeight: '600' }}>
            {patientFile?.childFirstName} {patientFile?.childLastName}
          </Typography>
          <Box sx={{ display: 'flex', gap: 3, pt: 2, pb: 3, flexWrap: 'wrap' }}>
            <PatientVideo />
            <PatientDetails />
          </Box>
          {isReviewed ? <PatientReviewResult /> : <PatientReview key={patientFile?.fileUrl} />}
        </>
      )}
    </Box>
  );
};
