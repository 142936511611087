import { VideoPlayer } from '../VideoPlayer';
import Box from '@mui/material/Box';
import { VideoPlayerProvider } from '../../contexts/VideoPlayerContext';

export const PatientVideo = () => {
  return (
    <Box
      sx={{
        width: '478px',
        pb: '58px',
      }}
    >
      <VideoPlayerProvider>
        <VideoPlayer />
      </VideoPlayerProvider>
    </Box>
  );
};
