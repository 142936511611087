import { IconButton, InputAdornment, styled, Typography } from '@mui/material';
import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { CalendarIcon } from '../../assets/icons/CalendarIcon';
import dayjs from 'dayjs';
import { COLORS } from '../../constants';
import { CloseIcon } from '../../assets/icons/CloseIcon';
import { useState } from 'react';

interface DatePickerProps {
  placeholder: string;
  label?: string;
  maxDate?: Date | null;
  minDate?: Date | null;
  value: Date | null;
  onChange?: (date: string | null) => void;
}

export const CustomDatePicker = ({
  value,
  onChange,
  label,
  placeholder,
  maxDate,
  minDate,
}: DatePickerProps) => {
  const [open, setOpen] = useState(false);

  const handleDateChange = (date: Date | null) => {
    onChange?.(date instanceof Date ? dayjs(date).format('YYYY-MM-DD') : null);
  };

  const handleIconClick = () => {
    if (!value) {
      setOpen(true);
    }
    onChange?.('');
  };

  const getInputAdornment = () => {
    return (
      <InputAdornment position="end">
        <IconButton onClick={handleIconClick}>
          {value ? <CloseIcon /> : <CalendarIcon />}
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <>
      <Typography variant="label">{label}</Typography>
      <DatePickerStyled
        onClose={() => setOpen(false)}
        open={open}
        value={value}
        onChange={(newValue) => handleDateChange(newValue as Date)}
        maxDate={maxDate}
        minDate={minDate}
        slots={{ inputAdornment: getInputAdornment }}
        slotProps={{
          textField: {
            placeholder,
          },
          popper: {
            className: 'custom-date-picker',
          },
        }}
      />
    </>
  );
};

const DatePickerStyled = styled(DatePicker)(() => ({
  '.MuiInputBase-root': {
    width: '296px',
    borderWidth: '2px',
    borderColor: COLORS.BLACK_SECOND,
    borderRadius: '8px',
    fontSize: '1rem',
    height: '48px',
  },
  input: {
    height: '48px',
    fontSize: '1rem',
  },
}));
