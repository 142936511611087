import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Sidebar } from '../../Sidebar';

export const LayoutSidebar = () => {
  return (
    <Box sx={{ height: '100%', display: 'flex' }}>
      <Sidebar />
      <Outlet />
    </Box>
  );
};
