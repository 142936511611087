import { DEFAULT_URL, LOCALSTORAGE_FILTER_ROUTE_KEY } from './constants';
import dayjs from 'dayjs';
import { selectedLanguage } from './index';

export const USER_BROWSER_LANGUAGE = navigator.language.slice(0, 2);

export const isValidEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const getLastDashboardFiltersUrl = (): string => {
  return localStorage.getItem(LOCALSTORAGE_FILTER_ROUTE_KEY) || DEFAULT_URL.DASHBOARD;
};

export const setLastDashboardFiltersUrl = (pathname: string, search: string) => {
  localStorage.setItem(LOCALSTORAGE_FILTER_ROUTE_KEY, pathname + search);
};

export const getDateInLocaleFormat = (date: string) =>
  dayjs(date).locale(selectedLanguage).format('L LT');
