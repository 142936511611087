import { Box, Button } from '@mui/material';
import { Status } from '../Status';
import * as React from 'react';
import { useGetPatientFile } from '../../api/queries/patient/useGetFile';
import { useParams } from 'react-router-dom';
import { usePatientFiles } from '../../api/queries/patient/usePatientFiles';
import { REVIEW_STATUS } from '../../contexts/PatientListProvider';
import { useTranslation } from 'react-i18next';
import { PatientReviewArrows } from '../PatientReviewArrows';

export const PatientReviewResult = () => {
  const { t } = useTranslation();
  const { patientId, selectedFileId } = useParams();
  const { data: patientFile } = useGetPatientFile({
    patientId: patientId || '',
    fileId: selectedFileId || '',
  });
  const { data: patientFilesData } = usePatientFiles({ patientId: patientId || '' });
  const data = patientFilesData?.files.find((f) => f.fileId === selectedFileId);

  const isAllReviewed = !patientFilesData?.files.find(
    (f) => f.fileReviewStatus === REVIEW_STATUS.AWAITING_REVIEW
  );

  return (
    <Box sx={{ maxWidth: '498px', mt: '4px' }}>
      {data?.fileReviewStatus && <Status variant={data.fileReviewStatus as REVIEW_STATUS} />}
      <Box sx={{ mt: 2 }}>{t('patient.result.title')}</Box>

      <Box sx={{ minHeight: '160px' }}>
        <Box sx={{ fontWeight: '600', mt: 1 }}>{t('patient.result.result')}</Box>
        <Box>{t(`reviewResult.${patientFile?.reviewResult}`)}</Box>

        {patientFile?.seizureType && (
          <>
            <Box sx={{ fontWeight: '600', mt: 1 }}>{t('patient.result.type')}</Box>
            <Box>{t(`reviewSeizure.${patientFile?.seizureType}`)}</Box>
          </>
        )}

        {patientFile?.reviewDescription && (
          <>
            <Box sx={{ fontWeight: '600', mt: 1 }}>{t('patient.result.comment')}</Box>
            <Box sx={{ whiteSpace: 'pre-wrap' }}>{patientFile?.reviewDescription}</Box>
          </>
        )}
      </Box>

      <Box sx={{ mt: 2, gap: 2, display: 'flex', flexWrap: 'wrap' }}>
        {!isAllReviewed && (
          <Button
            disabled={true}
            type="submit"
            variant="contained"
            size="medium"
            color="secondary"
            sx={{ minWidth: '290px', mr: 3 }}
          >
            {t('patient.submit')}
          </Button>
        )}
        <PatientReviewArrows />
        {!isAllReviewed && (
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            color="secondary"
            disabled={isAllReviewed}
          >
            {t('patient.submitAndSkip')}
          </Button>
        )}
      </Box>
    </Box>
  );
};
