import { Box } from '@mui/material';
import { VideoPlayerTime } from '../VideoPlayerTime';
import { VideoPlayerTimeline } from '../VideoPlayerTimeline';
import { PlayPauseButton } from '../PlayPauseButton';
import { SyntheticEvent } from 'react';
import { useVideoPlayerContext } from '../../../contexts/VideoPlayerContext';

type ControlsProps = {
  handlePlayPause: () => void;
  seekHandler: (e: SyntheticEvent | Event) => void;
  seekMouseUpHandler: (e: SyntheticEvent | Event, value: number | number[]) => void;
  onSeekMouseDownHandler: (e: SyntheticEvent | Event) => void;
};

export const Controls = ({
  handlePlayPause,
  seekHandler,
  seekMouseUpHandler,
  onSeekMouseDownHandler,
}: ControlsProps) => {
  const {
    videoState: { playing, played },
  } = useVideoPlayerContext();

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          flexGrow: '1',
          mt: 1,
        }}
      >
        <Box
          sx={{
            height: '24px',
            width: '24px',
          }}
        >
          <PlayPauseButton isPlaying={playing} size="small" handlePlayPause={handlePlayPause} />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <VideoPlayerTimeline
            color="primary"
            played={played}
            onSeekMouseDownHandler={onSeekMouseDownHandler}
            seekHandler={seekHandler}
            seekMouseUpHandler={seekMouseUpHandler}
          />
        </Box>
      </Box>
      <Box sx={{ pl: 3, pt: '4px' }}>
        <VideoPlayerTime />
      </Box>
    </Box>
  );
};
