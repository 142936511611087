import { useMutation } from '@tanstack/react-query';
import { keyCloakClient } from '../client/keycloakClient';
import { setAuthTokens } from './utils';

type SignInProps = {
  username: string;
  password: string;
};

type SignInRO = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  id_token: string;
};

export const useSignIn = () =>
  useMutation({
    mutationFn: async ({
      username,
      password,
    }: {
      username: string;
      password: string;
    }): Promise<void> => {
      const { access_token, refresh_token, refresh_expires_in, expires_in, id_token } =
        await authSignIn({
          username,
          password,
        });
      setAuthTokens(access_token, expires_in, refresh_token, refresh_expires_in, id_token);
    },
  });

const authSignIn = async (data: SignInProps): Promise<SignInRO> =>
  (await keyCloakClient)({
    url: '/realms/motus/protocol/openid-connect/token',
    method: 'POST',
    data: {
      ...data,
      grant_type: 'password',
      scope: 'openid',
      client_id: 'motus-app',
    },
  });
