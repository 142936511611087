import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../index';
import { baseClient } from '../client/baseClient';
import { REVIEW_RESULT_STATUS } from './constants';

export type PatientFileRO = {
  fileId: string;
  fileName: string;
  fileUploadedAt: string;
  fileReviewStatus: string;
  fileReviewResult: string;
  thumbnailUrl: string;
  reviewResult: REVIEW_RESULT_STATUS;
};

export type PatientFilesRO = {
  childId: string;
  files: PatientFileRO[];
};

type usePatientsProps = {
  patientId: string;
};

export const usePatientFiles = (props: usePatientsProps) =>
  useQuery({
    queryKey: [QUERY_KEYS.PATIENT_FILES, props],
    queryFn: () => verification(props),
  });

const verification = async ({ patientId }: usePatientsProps): Promise<PatientFilesRO> => {
  return baseClient({
    url: `/patients/${patientId}/files`,
    method: 'GET',
  });
};
