import { Box, ListItem, ListItemButton, Typography } from '@mui/material';
import { activeStyles, baseStyles, selectedItemStyles } from '../../Sidebar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MutableRefObject, useEffect, useRef } from 'react';
import { PatientRO } from '../../../api/queries/patient/usePatients';

type PatientListItemProps = {
  patient: PatientRO;
  parentRef: MutableRefObject<HTMLUListElement | null>;
  selectedPatientId?: string;
  handleScrollbarPosition: (value: number) => void;
};

export const PatientListItem = ({
  patient,
  selectedPatientId,
  handleScrollbarPosition,
  parentRef,
}: PatientListItemProps) => {
  const { childFirstName, childLastName, childId, filesToReviewCount } = patient;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const ref = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    if (selectedPatientId === childId && parentRef?.current && ref?.current) {
      const rect = ref.current.getBoundingClientRect();
      const scrollTop = rect.top - rect.height + 10;
      handleScrollbarPosition(scrollTop);
    }
  }, [selectedPatientId, parentRef, childId, handleScrollbarPosition]);

  return (
    <ListItem
      ref={ref}
      key={`${childFirstName}${childLastName}`}
      disablePadding
      data-id={childId}
      sx={{ mt: 0.5 }}
    >
      <ListItemButton
        sx={selectedItemStyles}
        selected={selectedPatientId === childId}
        onClick={() => navigate(`/dashboard/patient/${childId}`)}
      >
        <Box sx={selectedPatientId === childId ? { ...baseStyles, ...activeStyles } : baseStyles}>
          <Typography variant="body2">
            {childFirstName} {childLastName}
          </Typography>
          <Typography sx={{ fontSize: '0.75rem', opacity: 0.7 }}>
            {filesToReviewCount} {t('patient.videosToWatch')}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};
