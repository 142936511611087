import { Box, ListItem, ListItemButton, Typography } from '@mui/material';
import { useGetPatientFile } from 'api/queries/patient/useGetFile';
import { useNavigate, useParams } from 'react-router-dom';
import { activeStyles, baseStyles, selectedItemStyles } from '../index';
import { useTranslation } from 'react-i18next';

export const ReviewedPatientItemList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { patientId, selectedFileId } = useParams();
  const { data: patientFile } = useGetPatientFile({
    patientId: patientId || '',
    fileId: selectedFileId || '',
  });

  return (
    <>
      {patientFile && (
        <ListItem
          key={`${patientFile.childFirstName}${patientFile.childLastName}`}
          disablePadding
          sx={{ mt: 0.5 }}
        >
          <ListItemButton
            sx={selectedItemStyles}
            selected={patientId === patientFile.childId}
            onClick={() => navigate(`/dashboard/patient/${patientFile.childId}`)}
          >
            <Box
              sx={
                patientId === patientFile.childId ? { ...baseStyles, ...activeStyles } : baseStyles
              }
            >
              <Typography variant="body2">
                {patientFile.childFirstName} {patientFile.childLastName}
              </Typography>
              <Typography sx={{ fontSize: '0.75rem', opacity: 0.7 }}>
                0 {t('patient.videosToWatch')}
              </Typography>
            </Box>
          </ListItemButton>
        </ListItem>
      )}
    </>
  );
};
