import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../index';
import { baseClient } from '../client/baseClient';

export type PatientFileRO = {
  childId: string;
  childFirstName: string;
  childLastName: string;
  parentFirstName: string;
  parentLastName: string;
  invitationCode: string;
  parentEmail: string;
  fileName: string;
  fileCreatedAt: string;
  fileUploadedAt: string;
  fileUrl: string;
  reviewResult: string;
  seizureType: string;
  reviewDescription: string;
  reviewCreatedAt: string;
  thumbnailUrl: string;
  encryptionKey: string;
  encryptionKeyMD5: string;
};

type useGetPatientFileProps = {
  patientId: string;
  fileId: string;
};

export const useGetPatientFile = (props: useGetPatientFileProps) =>
  useQuery({
    queryKey: [QUERY_KEYS.PATIENT_FILES, props],
    queryFn: () => verification(props),
  });

const verification = async ({
  patientId,
  fileId,
}: useGetPatientFileProps): Promise<PatientFileRO> => {
  return baseClient({
    url: `/patients/${patientId}/files/${fileId}`,
    method: 'GET',
  });
};
