import { Box, Button, styled } from '@mui/material';
import { ArrowLeft } from '../../assets/icons/ArrowLeft';
import { ArrowRight } from '../../assets/icons/ArrowRight';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePatientFiles } from '../../api/queries/patient/usePatientFiles';
import { COLORS } from '../../constants';
import { getSortedPatientFiles } from '../../api/queries/patient/utils';

export const PatientReviewArrows = () => {
  const navigate = useNavigate();
  const { patientId, selectedFileId } = useParams();
  const { data } = usePatientFiles({ patientId: patientId || '' });
  const files = getSortedPatientFiles(data?.files || []);

  const currentIndex = files?.findIndex((f) => f.fileId === selectedFileId) || 0;
  const prevIndex = currentIndex - 1;
  const nextIndex = currentIndex + 1;

  const prev = files[prevIndex];
  const next = files[nextIndex];

  const handleVideoChange = (fileId?: string) => {
    if (!fileId) return;
    navigate(`/dashboard/patient/${patientId}/file/${fileId}`);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <ArrowButtonStyled
        variant="outlined"
        size="medium"
        color="secondary"
        disabled={!prev?.fileId}
        onClick={() => handleVideoChange(prev?.fileId)}
      >
        <ArrowLeft />
      </ArrowButtonStyled>
      <ArrowButtonStyled
        variant="outlined"
        size="medium"
        color="secondary"
        disabled={!next?.fileId}
        onClick={() => handleVideoChange(next?.fileId)}
      >
        <ArrowRight />
      </ArrowButtonStyled>
    </Box>
  );
};

export const ArrowButtonStyled = styled(Button)(({ disabled }) => ({
  padding: '12px 24px',
  background: `${COLORS.WHITE}!important`,
  svg: {
    width: '24px',
    height: '24px',
  },
  'svg path': {
    fill: disabled ? '#c6c6c6' : COLORS.GREEN_SECOND,
  },
}));
