import { PatientFileRO } from './usePatientFiles';
import { REVIEW_STATUS } from '../../../contexts/PatientListProvider';

export const getFirstPatientFile = (files?: PatientFileRO[]) => {
  if (!files) return;
  return getSortedPatientFiles(files)?.[0];
};

export const getAwaitingReviewFiles = (files?: PatientFileRO[]) => {
  return files?.filter((file) => file.fileReviewStatus === REVIEW_STATUS.AWAITING_REVIEW) || [];
};

export const getSortedPatientFiles = (files: PatientFileRO[]) => {
  return files.sort(
    (
      { fileReviewStatus: fileReviewStatusA, fileUploadedAt: fileUploadedAtA },
      { fileReviewStatus: fileReviewStatusB, fileUploadedAt: fileUploadedAtB }
    ) => {
      if (
        fileReviewStatusA === REVIEW_STATUS.AWAITING_REVIEW &&
        fileReviewStatusB !== REVIEW_STATUS.AWAITING_REVIEW
      ) {
        return -1;
      } else if (
        fileReviewStatusA !== REVIEW_STATUS.AWAITING_REVIEW &&
        fileReviewStatusB === REVIEW_STATUS.AWAITING_REVIEW
      ) {
        return 1;
      } else {
        return new Date(fileUploadedAtA).getTime() - new Date(fileUploadedAtB).getTime();
      }
    }
  );
};
